import { getUser } from "../hooks/user.actions";
import { useState, useEffect, useRef } from "react";
import { useUserActions } from "../hooks/user.actions";
import axios from "axios";

// xxxxxxxxxxxxxxxx

function CommentsPanel(props){

    const {
        setShowCommentsOff,
        video_id,
        showModal,
        modalMessage,
        modalTitle
    } = props;

    const user = getUser();
    const [ comment, setComment ] = useState(null)
    const [ videoComments, setVideoComments ] = useState([])
    const [ commented, setCommented ] = useState(false)
    const [ commentUnderEditing, setCommentUnderEditing ] = useState("")
    const [ editedComment, setEditedComment ] = useState("")


    
    const userActions = useUserActions()


    const onCommentSubmit = () => {
        const postService = userActions.commentoAxios()
        if(!postService){
            showModal(true)
            modalTitle("Not logged in")
            modalMessage(" comment on a video")
            return
        }
        if (!comment){
            return
        }
        postService.post(process.env.REACT_APP_BASE_URL + `/comment/comment/${video_id}`, {"body": comment.trim()})
        .then((res)=> {
            setVideoComments([...videoComments, {"id": res.data.id,"body": res.data.body, "user": res.data.user, "username": res.data.username} ])
            setComment('')
            setCommented(true)
        })
        .catch((err) => {
            if (err.response['data'] === "Email not verified") {
                modalTitle("Email not verified!")
                modalMessage("verify your email to be able to comment on a video. we have sent you a verification email. check your spam if you do not see it in inbox.")
                showModal(true)
            }            
        })
    }


    const handleDeleteComment =  (commentID) => {
        const postService = userActions.commentoAxios()
        if(!postService){
            console.log("Must be logged in to delete a comment")
            showModal(true)
            modalMessage(" delete a comment")
            return
        }
        postService.delete(process.env.REACT_APP_BASE_URL + `/comment/delete/${commentID}`)
        .then((res)=> {
            const commentsListAfterDel = videoComments.filter((comment) => comment.id !== commentID)
            setVideoComments([...commentsListAfterDel])
        })
        .catch((err) => console.log(err))
    }


    const handleUpdateComment =  (commentID) => {
        const postService = userActions.commentoAxios()
        if(!postService){
            console.log("Must be logged in to update a comment")
            showModal(true)
            modalMessage(" update a comment")
            return
        }
        postService.patch(process.env.REACT_APP_BASE_URL + `/comment/update/${commentID}`, {body: editedComment.trim()})
        .then((res)=> {
            // const commentsListAfterDel = videoComments.filter((comment) => comment.id !== commentID)
            // setVideoComments([...commentsListAfterDel])
        })
        .catch((err) => console.log(err))
    }




    useEffect(() => {
        axios.get(process.env.REACT_APP_BASE_URL + `/comment/get_comments/${video_id}`)
        .then((res)=> {
            setVideoComments(res.data.results)
        })
    }, [])



    const divRef = useRef(null);
    useEffect(() => {
        if(commented && setVideoComments?.length > 1){
            divRef.current.scrollIntoView({ behavior: 'smooth' });
            setCommented(false)
        }
    });



    return(
        
            <div
            id="comments-container"
            className="row d-flex"
            style={{
                maxHeight: 300,
                height: "auto",
                // width: "100%",
                overflow: "scroll",
                marginBottom: "10vh"
            }}
            >
            <div
                className="col"
                style={{ paddingRight: 0, paddingLeft: 0, width: "100%", height: "100%" }}
            >
                <div
                className="row text-end sticky-top"
                style={{
                    background: "rgba(234,35,35,0)",
                    width: "100%",
                    marginRight: 0,
                    marginLeft: 0
                }}
                >
                <div
                    className="col"
                    style={{ position: "sticky", background: "#212529", height: 30 }}
                >
                    <svg
                    className="bi bi-x-lg"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    style={{ fontSize: 28, color: "rgb(255,255,255)", cursor: "pointer" }}
                    onClick={setShowCommentsOff}
                    >
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                    </svg>
                </div>
                </div>


                {/* Comments List ///////////////////////////////////////////////////////////////*/}
                <ul
                className="list-group"
                style={{
                    marginTop: 0,
                    width: "100%",
                    borderRadius: 0,
                    overflow: "hidden"
                }}
                >
                <small
                style={{
                    fontWeight: "bold",
                    fontFamily: '"Hachi Maru Pop", serif',
                    borderBottomWidth: 1,
                    borderBottomStyle: "solid",
                    height: 1
                }}
                >
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;
                </small>
                {videoComments?.map((comment) => (
                <ul
                className="list-group"
                style={{ marginTop: 0, width: "100%", borderRadius: 0, overflow: "hidden" }}
                >
                    <li
                        className="list-group-item d-flex flex-column justify-content-start align-items-stretch"
                        style={{ background: "rgb(255, 255, 255)", borderBottomStyle: "none" }}
                    >
                        <div
                        className="row"
                        style={{ borderBottomWidth: 1, borderBottomStyle: "solid" }}
                        >
                        <div className="col">
                            <small
                            style={{ fontWeight: "bold", fontFamily: '"Hachi Maru Pop", serif' }}
                            >
                            {comment.username} 
                            </small>
                        </div>
                        <div className="col d-flex flex-row justify-content-end align-items-center">








                        
                            {/* Edit delete comment icons ////////////////////////////////////*/}
                            {user?.id === comment?.user.replaceAll( '-', '')  && 
                            <>
                                <svg
                                className="bi bi-pencil"
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                                style={{ fontSize: 17, marginRight: 14 }}
                                onClick={()=> comment.id === commentUnderEditing ? setCommentUnderEditing("") : setCommentUnderEditing(comment.id)}
                                >
                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                </svg>
                                <svg
                                className="bi bi-x-lg"
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                                style={{ fontSize: 17, cursor: "pointer" }}
                                onClick={()=> handleDeleteComment(comment.id)}
                                >
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                </svg>
                            </>
                            
                            
                            }
                        </div>
                        </div>
                        <input
                        type="text"
                        span
                        className="text-break border-0"
                        style={{ fontFamily: '"Hachi Maru Pop", serif', fontSize: 14 }}
                        value={comment.id === commentUnderEditing ? editedComment : comment.body}
                        disabled={comment.id === commentUnderEditing ? false : true}
                        onChange={(e) => setEditedComment(e.target.value )}
                        onBlur = { ()=> handleUpdateComment(comment.id) }
                        />
                    </li>
                </ul>
                ))
                }
                <div ref={divRef} />
                {videoComments?.length === 0 &&
                
                <li
                className="list-group-item d-flex flex-column justify-content-start align-items-stretch"
                style={{ background: "rgb(255, 255, 255)", borderBottomStyle: "none" }}
                >
                <small
                style={{
                    fontWeight: "bold",
                    fontFamily: '"Hachi Maru Pop", serif',
                    borderBottomWidth: 1,
                    borderBottomStyle: "solid"
                }}
                >
                </small>
                <span
                className="text-break text-center"
                style={{ fontFamily: '"Hachi Maru Pop", serif', fontSize: 14 }}
                >
                <br/>
                No comments on this video yet
                <br/>
                <br/>
                </span>
                </li>
                }



                </ul>
                <div
                className="row sticky-bottom"
                style={{
                    width: "100%",
                    marginRight: 0,
                    marginLeft: 0,
                    background: "#ffffff"
                }}
                >


                {/* Comments input ///////////////////////////////////////////////////////////////*/}
                {user?.id  && 
                <div
                className="col d-flex flex-row justify-content-start"
                style={{ paddingRight: 6, paddingLeft: 6 }}
                >
                    <input
                    type="text"
                    style={{
                        width: "100%",
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderWidth: 1
                    }}
                    onChange={(e) => setComment(e.target.value )}
                    value={comment}
                    />
                    <button
                    className="btn btn-primary"
                    type="button"
                    style={{
                        fontFamily: '"Hachi Maru Pop", serif',
                        fontSize: 14,
                        borderBottomLeftRadius: 0,
                        borderTopLeftRadius: 0
                    }}
                    onClick={onCommentSubmit}
                    >
                    comment
                    </button>
                </div>
                }
                </div>
            </div>
            </div>
    )
}

export default CommentsPanel;