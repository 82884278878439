import Navbarx from "../componenets/Navbar";
import LoginForm from "../componenets/LoginForm";
import Offcanvasx from "../componenets/Offcanvas";

function Login(){

    return(
        <div className="container-fluid">
            <Navbarx/>
            <LoginForm/>
            <Offcanvasx/>
        </div>
    )
}

export default Login