import { useUserActions } from "../hooks/user.actions";
import { useState } from "react";
import { useNavigate } from "react-router-dom";



function UserCard(props){

    const {username,
            profile_pic,
            num_followers,
            num_videos,
            num_likes,
            is_following,
            user_id,
            same_user} = props

    // console.log("same_user: ", same_user)

    const [following, setFollowing] = useState(is_following)
    const [numFollowers, setNumFollowers] = useState(num_followers)


    const userActions = useUserActions()

      const navigate = useNavigate();
    const handleNavigateToProfile = () => {
        navigate(`/user/${user_id}/`);
      };


    const handleOnFollowBtnClick = () =>{
        const postService = userActions.followAxios()
        if(!postService){
            console.log("Must be logged in to follow")
            return
        }
        postService.post(process.env.REACT_APP_BASE_URL + `/user/follow/${user_id}/`)
        .then(()=> (following ?
                        setFollowing(false) & setNumFollowers((prevNum) => prevNum - 1) : 
                        setFollowing(true) & setNumFollowers((prevNum) => prevNum + 1)))
        .catch((err) => {
            try{
                if(err.response?.data[0] === "Can not follow yourself"){console.log("Can not follow yourself")}
            }catch{}
        })}


    return(
        <div    className="card"
                id="user-card"
                style={{
                            width: "315.16px",
                            // marginRight: 50,
                            marginBottom: 50 
                        }}
                
                            >
            <div className="card-body" style={{ paddingBottom: 11 }}>
                <div className="row">
                <div className="col d-flex flex-row justify-content-center align-items-center">
                    <img
                    className="rounded-circle"
                    style={{ width: 80, height: 80, marginRight: 43, cursor: "pointer" }}
                    src={profile_pic?.substring(0 , profile_pic.lastIndexOf("?"))}
                    alt={username}
                    onClick={handleNavigateToProfile}
                    />

                    {(following && !same_user)  &&
                        <button
                        className="btn btn-primary border rounded-0"
                        type="button"
                        style={{ fontFamily: '"Hachi Maru Pop", serif' }}
                        onClick={handleOnFollowBtnClick}
                        >
                        Following
                        </button>
                    }

                    {(!following && !same_user)  &&
                        <button
                        className="btn btn-primary border rounded-0"
                        type="button"
                        style={{ fontFamily: '"Hachi Maru Pop", serif' }}
                        onClick={handleOnFollowBtnClick}
                        >
                        Follow
                        </button>
                    }

                </div>
                </div>
                <div
                className="row d-flex flex-row justify-content-between"
                style={{ marginTop: 8 }}
                >
                <div
                    className="col d-flex flex-row justify-content-center"
                    style={{ paddingRight: 0, paddingLeft: 0 }}
                >
                    <small
                    className="text-truncate text-center"
                    style={{
                        fontFamily: '"Hachi Maru Pop", serif',
                        overflow: "hidden",
                        width: 132,
                        maxWidth: 132
                    }}
                    >
                    {username}
                    </small>
                </div>
                <div
                    className="col d-flex flex-row justify-content-center justify-content-sm-center"
                    style={{ maxWidth: 132 }}
                >
                    <small
                    className="text-truncate"
                    style={{ fontFamily: '"Hachi Maru Pop", serif', marginRight: 8 }}
                    >
                    {numFollowers}
                    </small>
                    <svg
                    className="bi bi-people"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    style={{ fontSize: 23 }}
                    >
                    <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
                    </svg>
                </div>
                </div>
                <div
                className="row d-flex flex-row justify-content-center align-items-center"
                style={{
                    marginTop: 13,
                    borderTop: "1px solid rgba(33,37,41,0.18)",
                    paddingTop: 6
                }}
                >
                <div
                    className="col d-flex flex-row justify-content-center align-items-center"
                    style={{ maxWidth: 152, minWidth: "auto" }}
                >
                    <svg
                    className="bi bi-camera-video"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    style={{ fontSize: 21 }}
                    >
                    <path
                        fillRule="evenodd"
                        d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2z"
                    />
                    </svg>
                    <small
                    className="text-truncate"
                    style={{ fontFamily: '"Hachi Maru Pop", serif', marginLeft: 10 }}
                    >
                    {num_videos}
                    </small>
                </div>
                <div
                    className="col d-flex flex-row justify-content-center align-items-center"
                    style={{ width: 153, maxWidth: 153, minWidth: "auto" }}
                >
                    <small
                    className="text-truncate"
                    style={{ fontFamily: '"Hachi Maru Pop", serif', marginRight: 8 }}
                    >
                    {num_likes}
                    </small>
                    <svg
                    className="bi bi-heart-fill"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    style={{ fontSize: 18 }}
                    >
                    <path
                        fillRule="evenodd"
                        d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"
                    />
                    </svg>
                </div>
                </div>
            </div>
            </div>

    )
}

export default UserCard