import React, { useState } from "react";
import { useUserActions } from "../hooks/user.actions";


function RegisterForm(){

    const [error, setError] = useState(null)
    const [usernameError, setUsernamError] = useState(null)
    const [emailError, setEmailError] = useState(null)
    const [passwordError, setPasswordError] = useState(null)




    const [form, setForm] = useState({
        username: "",
        email: "",
        password: "",
        passwordConf: "",
        });

    const userActions = useUserActions();

    const handleSubmit = (event) => {
        event.preventDefault();

        setError(null)
        setUsernamError(null)
        setEmailError(null)
        setPasswordError(null)

        if (form.password !== form.passwordConf){
            setPasswordError(["The two passwords must be the same"])
            return
        }
    
    
        const data = {
            username: form.username,
            password: form.password,
            email: form.email,
        };
    
        userActions.register(data).catch((err) => {
            if(err.message){
                try{
                        const errMessage = JSON.parse(err.request.response);
                        console.log("errMessage: ", errMessage)
                        if (errMessage["detail"]){
                            setError(errMessage["detail"])
                        }
                        if (errMessage["email"]){
                            setEmailError(errMessage["email"])
                        }
                        if (errMessage["password"]){
                            setPasswordError(errMessage["password"])
                        }
                        if (errMessage["username"]){
                            setUsernamError(errMessage["username"])
                        }
                        console.log("passwordError: ", errMessage["password"][0])
                        console.log("typeof", typeof(errMessage["password"][0]))
                    }
                    catch(err){}
                // console.log("errMessage: ", err.message)
            }
        });
        };


    return(
            <div id="register-form-lg" className="row">
                <div
                className="col-lg-5 col-xl-5 col-xxl-5 d-flex flex-column align-items-sm-center align-items-md-center flex-lg-column align-items-lg-end flex-xl-column align-items-xl-end flex-xxl-column align-items-xxl-end"
                style={{
                    background: "rgba(220,7,7,0)",
                    marginTop: 0,
                    marginLeft: 0
                }}
                >
                <form
                    className="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex flex-column align-items-center flex-sm-column align-items-sm-center flex-md-column align-items-md-center flex-lg-column align-items-lg-end flex-xl-column flex-xxl-column"
                    onSubmit={handleSubmit}
                    style={{ marginRight: 0, marginLeft: 0 }}
                >
                    <label
                    id="username-label"
                    className="form-label"
                    style={{
                        fontFamily: '"Hachi Maru Pop", serif',
                        fontSize: 14,
                        marginTop: 14,
                        marginRight: 140,
                        marginLeft: 0,
                        paddingLeft: 0
                    }}
                    >
                    User name
                    </label>
                    <input
                    className="border rounded-0 form-control"
                    type="text"
                    onChange={(e) => setForm({ ...form, username: e.target.value })}
                    style={{ width: 236, marginLeft: 7 }}
                    required
                    />
                    {
                        usernameError && 
                        <div style={{ marginTop: 0, width: "100%" }}>
                        <small>
                            <span style={{ color: "rgb(255, 0, 0)" }}>
                            <ul><li>{usernameError}</li></ul>
                            </span>
                        </small>
                        </div>
                    }

                    <label
                    id="email-label"
                    className="form-label"
                    style={{
                        marginTop: 14,
                        marginRight: 181,
                        marginLeft: 0,
                        fontFamily: '"Hachi Maru Pop", serif',
                        fontSize: 14,
                        paddingLeft: 0
                    }}
                    >
                    Email
                    </label>
                    <input
                    className="border rounded-0 form-control"
                    type="email"
                    onChange={(e) => setForm({ ...form, email: e.target.value })}
                    style={{ width: 236, marginRight: 0, marginLeft: 7 }}
                    required
                    />
                    {
                        emailError && 
                        <div style={{ marginTop: 0, width: "100%"}}>
                        <small>
                            <span style={{ color: "rgb(255, 0, 0)" }}>
                            <ul><li>{emailError}</li></ul>
                            </span>
                        </small>
                        </div>
                    }

                    <label
                    id="password-label"
                    className="form-label"
                    style={{
                        marginTop: 37,
                        marginRight: 147,
                        marginLeft: 0,
                        fontFamily: '"Hachi Maru Pop", serif',
                        fontSize: 14,
                        paddingLeft: 0
                    }}
                    >
                    Password
                    </label>
                    <input
                    className="border rounded-0 form-control"
                    type="password"
                    onChange={(e) => setForm({ ...form, password: e.target.value })}
                    style={{ width: 236, marginLeft: 7 }}
                    required
                    />

                    {
                        passwordError && 
                        <div style={{ marginTop: 0, width: "250px"}}>
                        <small>
                            <span style={{ color: "rgb(255, 0, 0)" }}>
                            <ul>
                            {passwordError.map((err) => { return<li>{err}<br/></li> })}
                            </ul>
                            </span>
                        </small>
                        </div>
                    }



                    <label
                    className="form-label"
                    style={{
                        marginRight: 76,
                        marginLeft: 0,
                        fontFamily: '"Hachi Maru Pop", serif',
                        fontSize: 14,
                        paddingTop: 12,
                        paddingLeft: 0
                    }}
                    >
                    Confirm Password
                    </label>
                    <input
                    className="border rounded-0 form-control"
                    type="password"
                    onChange={(e) => setForm({ ...form, passwordConf: e.target.value })}
                    style={{ width: 236, marginLeft: 7 }}
                    required
                    />



                    <button
                    className="btn btn-primary text-center border rounded-0"
                    type="submit"
                    style={{
                        marginTop: 60,
                        fontFamily: '"Hachi Maru Pop", serif',
                        width: "96%"
                        }}
                    >
                    Register
                    </button>
                    {
                        error && 
                        <div style={{ marginTop: 0, width: "100%"}}>
                        <small>
                            <span style={{ color: "rgb(255, 0, 0)" }}>
                            {error}
                            </span>
                        </small>
                        </div>
                    }
                </form>
                </div>
                <div
                className="col-lg-1 col-xl-1 col-xxl-1 d-none d-sm-none d-md-none d-lg-flex d-xl-flex d-xxl-flex flex-lg-row justify-content-lg-center flex-xl-row justify-content-xl-center flex-xxl-row justify-content-xxl-center"
                style={{
                    background: "rgba(34,126,146,0)",
                    marginTop: 0,
                    paddingTop: 37,
                    paddingBottom: 48,
                    marginLeft: 60
                }}
                >
                <div className="vr" style={{ marginRight: 0 }} />
                </div>
                <div
                className="col-lg-5 col-xl-5 col-xxl-5 d-none d-sm-none d-md-none d-lg-flex d-xl-flex d-xxl-flex flex-lg-column justify-content-lg-center align-items-lg-start flex-xl-column justify-content-xl-center align-items-xl-start flex-xxl-column justify-content-xxl-center align-items-xxl-start"
                style={{ background: "rgba(91,200,41,0)", marginTop: 0 }}
                >
                <small
                    style={{
                    marginLeft: 0,
                    fontFamily: '"Hachi Maru Pop", serif',
                    fontSize: 28,
                    marginTop: 0,
                    marginBottom: 0,
                    width: 360
                    }}
                >
                    MeowTok
                </small>
                <small
                    style={{
                    marginLeft: 0,
                    fontFamily: '"Hachi Maru Pop", serif',
                    fontSize: 28,
                    marginTop: 0,
                    marginBottom: 0,
                    width: 360
                    }}
                >
                    A place for cats lovers
                </small>
                </div>
            </div>
    )
};

export default RegisterForm;

