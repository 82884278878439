import PasswordResetForm from "../componenets/PasswordResetForm";
import Navbarx from "../componenets/Navbar";
import Offcanvasx from "../componenets/Offcanvas";
import { useParams } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from "react";



function PasswordReset(){


    const { token } = useParams();

    const [validToken, setValidToken] = useState(false)
    const [waitingForRes, setWaitingForRes] = useState(true)
    const [userData, setUserData] = useState({username: "", profile_pic: ""})


    useEffect(()=> {
        axios.post( process.env.REACT_APP_BASE_URL + `/auth/validate_token_noredirect/${token}`)
        .then((res) => {
            if(res.status === 200){
                setValidToken(true)
                setWaitingForRes(false)
                console.log("res: ", res)
                setUserData({
                    username: res.data.username,
                    profile_pic: res.data?.profile_pic
                })
            }
            else{
                setValidToken(false)
                setWaitingForRes(false)
            }
        })
        .catch((err) => {
            setValidToken(false)
            setWaitingForRes(false)
        })
    }, [])


    if(waitingForRes){return}
    
    if(!validToken){
        console.log("TOKEN NOT VAILD")
        return
    }


    return(
        <>
            <Navbarx />
            <PasswordResetForm
                username={userData.username}
                profile_pic={userData.profile_pic}
                token={token}/>
            <Offcanvasx />
        </>
    )

}


export default PasswordReset;