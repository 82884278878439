import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { Button } from "react-bootstrap";
import axiosService from "../helpers/axios";
import AWS from "aws-sdk";
import { getUser } from "../hooks/user.actions";
import Modal from "react-bootstrap/Modal";



function VideoUploadForm() {
const [file, setFile] = useState(null);
const [form, setForm] = useState({
    video_title: "",
    tags: "",
});

const [progressBar, setProgressBar] = useState(null);

const [showModal, setShowModal] = useState(false);
const [modalMessage, setModalMessage] = useState("");
const [modalTitle, setModalTitle] = useState("");

const handleModalClose = () => {setShowModal(false); window.location.reload();}

const handleSubmit = (event) => {
                event.preventDefault();
                const user = getUser();
                if (!user) {
                    setModalTitle("Not logged in !");
                    setModalMessage("Please log in to be able to upload a video.");
                    setShowModal(true);
                    return;
                }
                const videouploadform = event.currentTarget;
                if (videouploadform.checkValidity() === false) {
                event.stopPropagation();
                }


                const data = {
                    title: form.video_title,
                    uploaded_by: user.id,
                    tags: form.tags
                        .split(",")
                        ?.filter(function (item) {
                        return item !== ",";
                        })
                        ?.filter(function (item) {
                        return item.trim() !== "";
                        })
                        .slice(0, 3)
                        .map((item) => item.trim()),
                };

                if (file.size > 203000000){
                    setModalTitle("Max size!");
                    setModalMessage("Max allowed video size is 200 MP.");
                    setShowModal(true);
                    return
                }

                axiosService
                .post("/video/create_record", data)
                .then((res) => {
                    uploadFile(res.data.id, user.id);
                })
                .catch((err) => {
                    console.log("Terr: ", err);
                    console.log(err.response["data"]);
                    if (err?.response["data"] === "Email not verified") {
                        setModalTitle("Email not verified!");
                        setModalMessage("Verify your email to be able to upload a video. We have sent you a verification email. Check your spam if you do not see it in inbox.");
                        setShowModal(true);
                    } else {
                    console.log("err in handleSubmit: ", err);
                    }
                });
};

const uploadFile = async (video_record_id, uploaded_by_id) => {
    const S3_BUCKET = "funnyshop-backend-media";
    const REGION = "eu-north-1";

    AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });

    const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
    ContentType: 'video/jpeg'
    });
    

    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    today = 'videos/' + yyyy + '/' + mm + '/' + dd + '/';

    
    // Files Parameters
    const file_name_on_s3 = today + video_record_id + "__" + uploaded_by_id + "__" + file.name

    
    // console.log("file: ", file)

    const params = {
    Bucket: S3_BUCKET,
    Key: file_name_on_s3,
    Body: file,
    };

    // Uploading file to s3
    try{
        const upload = s3
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
            // File uploading progress
            setProgressBar(parseInt((evt.loaded * 100) / evt.total));
        })
        .promise();

        await upload.then((err, data) => {
            if (err) console.log(err);
            // Fille successfully uploaded
            axiosService
                .post("/video/confirm_video", { video_path_on_s3: file_name_on_s3 })
                .then((res) => {
                    setModalTitle("Success");
                    setModalMessage("Video uploaded successfully");
                    setShowModal(true);
                })
                .catch((err) => {
                console.log("err: ", err);
                });
            });

    }
    catch(err){
        if (err?.code === "AccessDenied"){
            setModalTitle("File type!");
            setModalMessage("Please make sure the video extension is mp4.");
            setShowModal(true);
        }
    }


};

///////////////////////////////////////////////////////////////////////////
const [videoFilePath, setVideoFilePath] = useState(null);
const handleVideoUpload = (event) => {
    setVideoFilePath(URL.createObjectURL(event.target.files[0]));
    const file = event.target.files[0];
    setFile(file);
};

let inputFile = null;
useEffect(() => {
    inputFile = document.getElementById("input-file");
}, [form]);

const handleUpload = () => {
    if (inputFile) {
    inputFile.click();
    }
};
////////////////////////////////////////////////////////////////////////////

return (
    <div
    id="upload-video-form"
    className="col-auto border border-3 p-4 border-dark d-flex flex-column justify-content-start align-items-center"
    // style={{background: "red"}}
    >
    <div className="row" style={{ width: "100%" }}>
        <div
        className="col d-flex flex-column justify-content-center align-items-center"
        style={{ paddingRight: 0, paddingLeft: 0 }}
        >

        {!videoFilePath ? (
            <>
            <input
                id="input-file"
                className="d-none"
                type="file"
                onChange={handleVideoUpload}
                accept="video/mp4"
            />
            <Button
                onClick={handleUpload}
                style={{ background: "white", border: "none" }}
            >
                <i
                className="las la-file-video d-flex flex-grow-1 flex-fill justify-content-center align-items-center align-content-center"
                style={{
                    fontSize: 110,
                    borderStyle: "solid",
                    color: "rgb(33,37,41)",
                }}
                size={70}
                />
            </Button>
            <small
                style={{ fontFamily: '"Hachi Maru Pop", serif', marginTop: 13 }}
            >
                Chose your video
            </small>
            </>
        ) : (
            <ReactPlayer
            url={videoFilePath}
            width="100%"
            height="300px"
            controls={true}
            />
        )}
        </div>
    </div>


    {progressBar && (
        <div
        className="progress border rounded-0"
        style={{ width: "100%", height: 32, marginTop: 26 }}
        >
        <div
            className="progress-bar bg-primary progress-bar-striped progress-bar-animated"
            aria-valuenow={progressBar}
            aria-valuemin={0}
            aria-valuemax={100}
            style={{ width: progressBar + "%" }}
        >
            {progressBar}%
        </div>
        </div>
    )}

    <div className="row" style={{ height: 60, width: "100%" }} />
    <div className="row" style={{ marginTop: 0, width: "100%" }}>
        <div className="col">
        <form onSubmit={handleSubmit}>
            <input
            className="border border-2 border-dark rounded-0 form-control"
            type="text"
            placeholder="Video title"
            style={{
                fontFamily: '"Hachi Maru Pop", serif',
                textAlign: "center",
            }}
            onChange={(e) =>
                setForm({ ...form, video_title: e.target.value })
            }
            required
            />
            <input
            id="video-tags-input"
            className="border border-2 border-dark rounded-0 form-control d-flex flex-wrap"
            type="text"
            style={{
                marginTop: 17,
                fontFamily: '"Hachi Maru Pop", serif',
                fontSize: 15,
                height: "89.5px",
                textAlign: "center",
            }}
            placeholder="Video tags. At least 1, max3."
            onChange={(e) => setForm({ ...form, tags: e.target.value })}
            required
            />


            {form.tags
            .split(",")
            ?.filter(function (item) {
                return item !== ",";
            })
            ?.filter(function (item) {
                return item.trim() !== "";
            }).length > 3 && (
            <small
                style={{ color: "red", fontFamily: '"Hachi Maru Pop", serif' }}
            >
                {" "}
                Max three tags per video{" "}
            </small>
            )}
            <div
            className="row d-flex flex-row justify-content-start align-items-start"
            style={{ marginTop: 15 }}
            >
            <div className="col d-flex">
                {form.tags.split(",")?.length < 4
                ? form.tags
                    .split(",")
                    ?.filter(function (item) {
                        return item !== ",";
                    })
                    ?.filter(function (item) {
                        return item.trim() !== "";
                    })
                    .map((tag, index) => (
                        <span
                        className="badge border rounded-0 border-1 border-black d-flex flex-row justify-content-start align-items-lg-center justify-content-xxl-start align-items-xxl-center"
                        style={{
                            background: "rgba(13,110,253,0)",
                            color: "rgb(0,0,0)",
                            fontFamily: '"Hachi Maru Pop", serif',
                            borderWidth: 3,
                            borderStyle: "solid",
                            paddingTop: 0,
                            paddingRight: 5,
                            paddingBottom: 0,
                            paddingLeft: 7,
                            marginRight: 12,
                            fontSize: 12,
                            height: 30,
                        }}
                        >
                        {tag}&nbsp;
                        </span>
                    ))
                : form.tags
                    .split(",")
                    ?.filter(function (item) {
                        return item !== ",";
                    })
                    ?.filter(function (item) {
                        return item.trim() !== "";
                    })
                    .slice(0, 3)
                    .map((tag, index) => (
                        <span
                        className="badge border rounded-0 border-1 border-black d-flex flex-row justify-content-start align-items-lg-center justify-content-xxl-start align-items-xxl-center"
                        style={{
                            background: "rgba(13,110,253,0)",
                            color: "rgb(0,0,0)",
                            fontFamily: '"Hachi Maru Pop", serif',
                            borderWidth: 3,
                            borderStyle: "solid",
                            paddingTop: 0,
                            paddingRight: 5,
                            paddingBottom: 0,
                            paddingLeft: 7,
                            marginRight: 12,
                            fontSize: 12,
                            height: 30,
                        }}
                        >
                        {tag}&nbsp;
                        </span>
                    ))}
            </div>
            </div>


            <button
            className="btn btn-primary border border-2 border-dark rounded-0"
            type="submit"
            style={{ width: "100%", marginTop: 40, fontFamily: '"Hachi Maru Pop", serif' }}
            >
            Upload
            </button>
            <small className="d-flex  flex-row justify-content-center"
            style={{fontFamily: '"Hachi Maru Pop", serif', fontSize: 12, width: "100%", marginTop: 5}}>
                Allowed types, mp4. Max size 200 MP.
            </small>
        </form>
        </div>
    </div>

    <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleModalClose}
        animation={false}>
        <div
        className="modal-dialog modal-dialog-centered border border-3 border-dark"
        role="html"
        style={{
            width: "100%",
            height: "100%",
            marginTop: 0,
            marginBottom: 0,
            padding: 0,
            margin: 0,
        }}
        >
        <div className="modal-content"
        style={{
        borderRadius: 0,
        background: "#fcf5e1"}}>
            
            <div className="d-flex flex-row justify-content-center">
                    <div className="col-11 col d-flex flex-column justify-content-center align-items-center"
                    style={{marginTop: 10}}>
                        <h5 style={{fontFamily: '"Hachi Maru Pop", serif', fontWeight: "bold"}}>{modalTitle}</h5>
                    </div>
                    <div className="col d-flex flex-row justify-content-end">
                        <button
                        onClick={handleModalClose}
                        style={{
                        background: "transparent",
                        border: "none"}}>
                            <svg
                            className="bi bi-x-lg"
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                            style={{ marginRight: 8, fontSize: 30, marginTop: 10}}>
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                            </svg>
                        </button>
                    </div>
                </div>
            <div className="modal-body">
            <p style={{ fontFamily: '"Hachi Maru Pop", serif' }}>
                {modalMessage}

            </p>
            </div>
            <div className="d-flex flex-row justify-content-end align-itmes-center">
            <button
                className="btn btn-primary border border-2 border-dark rounded-0"
                type="button"
                style={{ fontFamily: '"Hachi Maru Pop", serif', marginRight: 20, marginBottom: 10 }}
                onClick={handleModalClose}
            >
                close
            </button>
            </div>
        </div>
        </div>
    </Modal>
    </div>
);
}

export default VideoUploadForm;
