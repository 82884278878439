import Offcanvasx from "../componenets/Offcanvas";
import Sidebar from "../componenets/Sidebar";
import Navbarx from "../componenets/Navbar";
import VideoArea from "../componenets/VideoArea";
import {useSelector} from 'react-redux'
import { useState, useEffect } from 'react' 


function Home(){
    const sortbyState = useSelector(state => state.sortby)
    const [numIterate, setNumIterate] = useState(0)


    useEffect(() => {
        setNumIterate((prevNum) => prevNum + 1)
        if(numIterate + 1  >= 2){setNumIterate(0)}
        }, [sortbyState?.tag]);


    return(
            <div className="container-fluid d-flex flex-row justify-content-start align-items-start"
            style={{
                backgroundColor: "#fcf5e1"
            }}>
                <Navbarx />
                <Offcanvasx />
                {(sortbyState === "newest")? 
                                <VideoArea  fetchAPI={process.env.REACT_APP_BASE_URL + "/video/sort_by/newest/"} 
                                            numItemsInit={10}
                                            numItemsOnScroll={10}
                                            userVideoArea={false}
                                            sortbyState= {sortbyState}/> : <></>}
                {(sortbyState === "likes")?
                                <VideoArea  fetchAPI={ process.env.REACT_APP_BASE_URL + "/video/sort_by/likes/"}
                                            numItemsInit={10}
                                            numItemsOnScroll={10}
                                            userVideoArea={false}
                                            sortbyState= {sortbyState}/> : <></>}
                {(sortbyState === "views")?
                                <VideoArea  fetchAPI= { process.env.REACT_APP_BASE_URL + "/video/sort_by/views/"}
                                            numItemsInit={10}
                                            numItemsOnScroll={10}
                                            userVideoArea={false}
                                            sortbyState= {sortbyState}/> : <></>}
                {(sortbyState?.sortby === "tag") && numIterate === 0 ?
                                <VideoArea  fetchAPI={process.env.REACT_APP_BASE_URL + `/video/sort_by/tag/${sortbyState?.tag}`}
                                            numItemsInit={10}
                                            numItemsOnScroll={10}
                                            userVideoArea={false}
                                            sortbyState= {sortbyState}
                                            tag={sortbyState?.tag}/> : <></>}
                {(sortbyState?.sortby === "tag") && numIterate === 1 ?
                                <VideoArea  fetchAPI={process.env.REACT_APP_BASE_URL + `/video/sort_by/tag/${sortbyState?.tag}`}
                                            numItemsInit={10}
                                            numItemsOnScroll={10}
                                            userVideoArea={false}
                                            sortbyState= {sortbyState}
                                            tag={sortbyState?.tag}/> : <></>}
                <Sidebar />
            </div>
        )
}

export default Home;