import { useUserActions } from "../hooks/user.actions";
import { useState, useEffect } from "react";
import CommentsPanel from "./CommentsPanel";
import Modal from 'react-bootstrap/Modal';
import { getUser } from "../hooks/user.actions";
import { useSwipeable } from 'react-swipeable';



function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }



function VideoStreamVideo(props){

    const {
        video_srource,
        username,
        user_pic,
        video_id,
        num_views,
        user_public_id,
        tags,
        onMouseWheel,
        } = props;

        const handlers = useSwipeable({
            onSwipedUp: (eventData) => onMouseWheel(eventData),
            onSwipedDown: (eventData) => onMouseWheel(eventData),
            });

        
        // console.log("X1")

        const userActions = useUserActions();

        const [numLikes, setNumLikes] = useState(null);
        const [isLiking, setIsLiking] = useState(null);
        const [sleepFor100ms, setSleepFor100ms] = useState(true);
        const [showComments, setShowComments] = useState(false);

        const [showModal, setShowModal] = useState(false);
        const [modalMessage, setModalMessage] = useState("");
        const [modalTitle, setModalTitle] = useState("");


        const setShowCommentsOff = () => {setShowComments(false);}
        const handleModalClose = () => setShowModal(false);

        const handleOnLikeBtnClick = () =>{
            const postService = userActions.likeVideoAxios()
            if(!postService){
                console.log("Must be logged in to like video")
                setModalTitle("Not logged in!")
                setModalMessage("you must be logged in to like a video")
                setShowModal(true)
                return
            }
            postService.post(process.env.REACT_APP_BASE_URL + `/video/like_video/${video_id}/`)
            .then((res)=> {
                setNumLikes(res.data['likes'])
                setIsLiking(res.data['is_liking'])
            })
            .catch((err) => {
                console.log("UserProfile, handleOnFollowBtnClick, err: ", err)
            })}


            useEffect(() => {
                // console.log("X2")

                const postService = userActions.likeVideoAxios()
                if(!postService){
                    console.log("Must be logged in to like video")
                    return
                }
                postService.post(process.env.REACT_APP_BASE_URL +  `/video/dolike/${video_id}/`)
                .then((res)=> {
                    setNumLikes(res.data['likes'])
                    setIsLiking(res.data['is_liking'])
                })
            }, [])


            const handleNavigateToUserProfile = () => {
                window.location.href = `/user/${user_public_id}/`
            };

            const handleNavigateToLoginPage = () => {
                window.location.href = `/login/`
            };

            const handleNavigateToRegisterPage = () => {
                window.location.href = `/register/`
            };

            const handleNavigateToUploadPage = () => {
                const user = getUser();
                if(!user){
                setModalTitle("Not logged in!")
                setModalMessage("you must be logged in to upload a video")
                setShowModal(true)
                return
                }
                window.location.href = `/upload/`
            };

            sleep(1000).then(()=> {setSleepFor100ms(false)})


                return(
                    <>
                                    {/* Video //////////////////////////////////////////////////////////////*/}
                                    <video
                                    id="video-content"
                                    width={560}
                                    height={315}
                                    style={{ height: "90vh", width: "100%"}}
                                    controlsList="nofullscreen nodownload"
                                    controls={true}
                                    autoPlay={true}
                                    onWheel={(e) => (!sleepFor100ms && onMouseWheel(e)) }
                                    {...handlers}
                                    >
                                        
                                    <source
                                        src={video_srource}
                                        type="video/webm"
                                    />
                                    </video>
                    
                                    {/* User pic and username //////////////////////////////////////////////////////////////*/}
                                    <div
                                    id="videos-profilepic-username"
                                    className="d-flex flex-column justify-content-start align-items-center"
                                    style={{ position: "fixed"}}


                                    >
                                    <img
                                        className="rounded-circle"
                                        src={user_pic?.substring(0 , user_pic.lastIndexOf("?"))}
                                        alt={username}
                                        width={60}
                                        height={60}
                                        style={{cursor: "pointer"}}
                                        onClick={handleNavigateToUserProfile}
                                    />
                                    <small
                                        className="text-center ms-auto"
                                        style={{
                                        color: "rgb(255,255,255)",
                                        fontFamily: '"Hachi Maru Pop", serif',
                                        marginRight: 15,
                                        cursor: "pointer"
                                        }}
                                        onClick={handleNavigateToUserProfile}
                                    >
                                        {username}
                                    </small>
                                    </div>
                    
                    
                                    {/* Num Likes //////////////////////////////////////////////////////////////*/}
                                    <div
                                    id="videos-likes"
                                    className="d-flex flex-column justify-content-start align-items-center"
                                    style={{ position: "fixed" }}
                                    >



                                    {/* Likes Icon ///////////////////////////////////////////////////////////*/}
                                    {isLiking ? 
                                    <svg
                                    className="bi bi-heart-fill"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    viewBox="0 0 16 16"
                                    style={{ fontSize: 34 , color: "white", cursor: "pointer" }}
                                    onClick={handleOnLikeBtnClick}

                                    >
                                    <path
                                        fillRule="evenodd"
                                        d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"
                                    />
                                    </svg>
                                    :
                                    <svg
                                        className="bi bi-heart"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="1em"
                                        height="1em"
                                        fill="currentColor"
                                        viewBox="0 0 16 16"
                                        style={{ fontSize: 29, color: "white", cursor: "pointer"}}
                                        onClick={handleOnLikeBtnClick}

                                    >
                                        <path
                                        fillRule="evenodd"
                                        d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                                    </svg>
                                }
                                    <small
                                        className="text-center mx-auto"
                                        style={{
                                        color: "rgb(255,255,255)",
                                        fontFamily: '"Hachi Maru Pop", serif'
                                        }}
                                    >
                                        {numLikes}
                                    </small>
                                    </div>
                    
                    
                                    {/* Num Views //////////////////////////////////////////////////////////////*/}
                                    <div
                                    id="videos-views"
                                    className="d-flex flex-row justify-content-center align-items-center"
                                    style={{ position: "fixed" }}
                                    >
                                    <svg
                                        className="bi bi-eye-fill"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="1em"
                                        height="1em"
                                        fill="currentColor"
                                        viewBox="0 0 16 16"
                                        style={{ fontSize: 23, color: "rgb(255,255,255)", marginRight: 5 }}
                                    >
                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                    </svg>
                                    <small
                                        className="text-center mx-auto"
                                        style={{
                                        color: "rgb(255,255,255)",
                                        fontFamily: '"Hachi Maru Pop", serif'
                                        }}
                                    >
                                        {num_views}
                                    </small>
                                    </div>
                    
                    
                                    {/* Tags //////////////////////////////////////////////////////////////*/}
                                    <div
                                    id="videos-tags-div"
                                    className="d-flex flex-row justify-content-start align-content-start flex-wrap"
                                    style={{
                                        position: "fixed",
                                        width: 170,
                                        maxWidth: 170,
                                        height: "40.5px",
                                        overflow: "hidden"
                                    }}
                                    >

                                    {tags?.map((tag) => (
                                    <small
                                    className="text-break"
                                    style={{
                                    fontFamily: '"Hachi Maru Pop", serif',
                                    borderWidth: 2,
                                    borderStyle: "solid",
                                    paddingRight: 8,
                                    paddingLeft: 8,
                                    marginRight: 3,
                                    color: "rgb(255,255,255)",
                                    fontSize: 11
                                    }}
                                    >
                                        {tag}
                                    </small>
                                    ))}


                                    </div>
                                    <div
                                    className="row"
                                    style={{
                                        height: "10vh",
                                        maxHeight: "10vh",
                                        minHeight: "10vh",
                                        width: "100%",
                                        background: "#212529"
                                    }}
                                    >
                                    
                                    <div className="col d-flex flex-row justify-content-around align-items-stretch">
                    
                    
                                        {/* Comment button //////////////////////////////////////////////////////////////*/}
                                        <button
                                        className="btn btn-primary"
                                        type="button"
                                        style={{ borderStyle: "none", width: "100%" }}
                                        onClick={() => {showComments ? setShowComments(false) : setShowComments(true)}}
                                        >
                                        <svg
                                            className="bi bi-chat-left-dots"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="1em"
                                            height="1em"
                                            fill="currentColor"
                                            viewBox="0 0 16 16"
                                            style={{ fontSize: 34 }}
                                        >
                                            <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                                            <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                                        </svg>
                                        </button>
                    
                    
                                        {/* Upload button //////////////////////////////////////////////////////////////*/}
                                        <button
                                        className="btn btn-primary"
                                        type="button"
                                        style={{ borderStyle: "none", width: "100%" }}
                                        onClick={handleNavigateToUploadPage}
                                        >
                                        <svg
                                            className="bi bi-plus-circle"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="1em"
                                            height="1em"
                                            fill="currentColor"
                                            viewBox="0 0 16 16"
                                            style={{ fontSize: 34 }}
                                        >
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                                        </svg>
                                        </button>
                    
                    
                                        {/* Like button //////////////////////////////////////////////////////////////*/}
                                        <button
                                        className="btn btn-primary"
                                        type="button"
                                        style={{ borderStyle: "none", width: "100%" }}
                                        onClick={handleOnLikeBtnClick}
                                        >
                                        <svg
                                            className="bi bi-heart"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="1em"
                                            height="1em"
                                            fill="currentColor"
                                            viewBox="0 0 16 16"
                                            style={{ fontSize: 34 }}
                                        >
                                            <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                                        </svg>
                                        </button>
                                    </div>
                                    </div>
                                    {showComments &&
                                        <CommentsPanel
                                        setShowCommentsOff = {setShowCommentsOff}
                                        video_id={video_id}
                                        showModal = {setShowModal}
                                        modalMessage = {setModalMessage}
                                        modalTitle = {setModalTitle}
                                        />}



                                    {/* Modal //////////////////////////////////////////////////////////////*/}

                                    <Modal
                                    size="md"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    show={showModal}
                                    onHide={handleModalClose} animation={false}>

                                    <div className="modal-dialog modal-dialog-centered" role="html"
                                        style={{width: "100%", height: "100%", marginTop: 0, marginBottom: 0}}>
                                        <div className="modal-content">
                                        <div className="modal-header">
                                            <h4
                                            className="modal-title"
                                            style={{ fontFamily: '"Hachi Maru Pop", serif' }}
                                            >
                                            {modalTitle}
                                            </h4>
                                            <button
                                            className="btn-close"
                                            type="button"
                                            aria-label="Close"
                                            data-bs-dismiss="modal"
                                            onClick={handleModalClose}
                                            />
                                        </div>
                                        <div className="modal-body">
                                            <p style={{ fontFamily: '"Hachi Maru Pop", serif' }}>
                                            {modalMessage}
                                            </p>
                                        </div>
                                        <div className="modal-footer">
                                        

                                            {modalTitle !== "Email not verified!" &&
                                            <>
                                            <button
                                            className="btn btn-primary border rounded-0 border-0"
                                            type="button"
                                            style={{
                                                fontFamily: '"Hachi Maru Pop", serif',
                                                marginRight: 1,
                                                marginLeft: 4
                                            }}
                                            onClick={handleNavigateToLoginPage}
                                            >
                                            login
                                            </button>
                                            <button
                                            className="btn btn-primary border rounded-0 border-0"
                                            type="button"
                                            style={{
                                                fontFamily: '"Hachi Maru Pop", serif',
                                                marginLeft: 0,
                                                marginRight: 27,
                                                borderLeftStyle: "none"
                                            }}
                                            onClick={handleNavigateToRegisterPage}
                                            >
                                            register
                                            </button>
                                            </>}
                                            <button
                                            className="btn btn-primary border rounded-0 border-0"
                                            type="button"
                                            style={{ fontFamily: '"Hachi Maru Pop", serif' }}
                                            onClick={handleModalClose}
                                            >
                                            close
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                    </Modal>

                                </>
                    
                        )
};

export default VideoStreamVideo;