    import React, { useState } from "react";
    import axios from "axios";
    import Toaster from "./Toaster";

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    function PasswordResetForm(props) {
    const { username, profile_pic, token } = props;

    const [form, setForm] = useState({
        password: "",
        passwordConf: "",
    });
    const [passwordError, setPasswordError] = useState(null);

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setPasswordError("");

        if (form.password !== form.passwordConf) {
        setPasswordError(["The two passwords must be the same"]);
        return;
        }
        axios
        .post(process.env.REACT_APP_BASE_URL + "/auth/password_reset/confirm", {
            token: token,
            password: form.password,
        })
        .then((res) => {
            setToaster({
            type: "success",
            message: "You will be sent to login page in 5 seconds.",
            show: true,
            title: "Success",
            delay: 7000,
            });

            sleep(5000).then(() => {
            window.location.href = `/login/`;
            });
        })
        .catch((err) => setPasswordError(err.response.data?.password));
    };

    const [toaster, setToaster] = useState({
        title: "",
        show: false,
        message: "",
        type: "",
    });

    return (
        <div
        className="container"
        style={{ marginTop: 101, paddingRight: 0, paddingLeft: 0 }}
        >
        <div className="row d-flex flex-column justify-content-center align-items-center">
            {
            profile_pic && (
            <>

                <div className="col">
                    <div
                    className="vl"
                    style={{
                    marginTop: "61px",
                    borderWidth: 1,
                    borderStyle: "solid",
                    }}/>
                </div>



                <div className="col d-flex flex-column justify-content-center align-items-center">
                    <img
                    id="profile-image"
                    className="rounded-circle"
                    width="120px"
                    height="120px"
                    style={{
                    margin: 0,
                    width: 120,
                    height: 120,
                    background: "white",
                    marginTop: "-50px"
                    }}
                    src={profile_pic?.substring(0, profile_pic.lastIndexOf("?"))}/>
                </div>




            </>
            )}

            <div className="col d-flex flex-row justify-content-center align-items-center">
            <small style={{ fontFamily: '"Hachi Maru Pop", serif' }}>
                {username}
            </small>
            </div>
        </div>
        <div
            id="password-reset-form"
            className="row d-flex flex-column justify-content-center align-items-center align-content-center"
            style={{ marginTop: 26 }}
        >
            <form onSubmit={handleFormSubmit}>
            <div
                className="col d-flex justify-content-center align-items-center"
                style={{ width: "100%" }}
            >
                <small
                className="d-flex justify-content-center"
                style={{
                    fontFamily: '"Hachi Maru Pop", serif',
                    width: "100%",
                    fontWeight: "bold",
                }}
                >
                Password Reset
                </small>
            </div>
            <div
                className="col d-flex justify-content-center align-items-center"
                style={{ width: "100%" }}
            >
                <input
                id="enter-email-input"
                type="password"
                style={{ marginTop: 26, width: "100%", textAlign: "center" }}
                placeholder="New Password"
                onChange={(e) => setForm({ ...form, password: e.target.value })}
                />
            </div>
            <div
                className="col d-flex justify-content-center align-items-center"
                style={{ width: "100%" }}
            >
                <input
                id="enter-email-input"
                type="password"
                placeholder="Repeat New Password"
                style={{ marginTop: 29, width: "100%", textAlign: "center" }}
                onChange={(e) =>
                    setForm({ ...form, passwordConf: e.target.value })
                }
                />
            </div>
            <div
                className="col d-flex flex-column justify-content-center align-items-stretch"
                style={{ marginTop: 29, width: "100%" }}
            >
                <button
                className="btn btn-primary border rounded-0"
                type="submit"
                style={{ width: "100%", fontFamily: '"Hachi Maru Pop", serif' }}
                >
                Change
                </button>
            </div>
            {/* <small className="text-danger">{passwordError}</small> */}
            {passwordError && (
                <ul>
                {passwordError.map((error) => {
                    return <li className="text-danger">{error}</li>;
                })}
                </ul>
            )}
            </form>
        </div>

        <Toaster
            title={toaster.title}
            message={toaster.message}
            type={toaster.type}
            showToast={toaster.show}
            onClose={() => setToaster({ ...toaster, show: false })}
            delay={toaster.delay}
        />
        </div>
    );
    }

    export default PasswordResetForm;
